<template>
  <div style="padding-top: 50px;">
    <div class="clearfix profile-wrapper">
      <div class="former-core-info">
        <v-card class="former-core-info-header">
          <div class="avatar-wrapper">
            <v-img
              lazy-src="../../../assets/Search-SVG/avatar-placeholder.jpeg"
              :src="avatarUrl"
              class="former-profile-img"
            />
            <div class="avatar-outline" />
          </div>
          <div class="header-info">
            <h2 class="former-name">{{ name }}</h2>
            <div class="rating-container">
              <span class="grey--text text--lighten-2 text-caption mr-2">
                ({{ rating }})
              </span>
              <v-rating
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                readonly
              />
            </div>
          </div>
        </v-card>
        <v-card class="former-description">
          <v-icon style="align-self: flex-start; padding: 10px;">
            mdi-format-quote-open
          </v-icon>
          <p>{{ description }}</p>
          <v-icon style="align-self: flex-end; padding: 10px;">
            mdi-format-quote-close
          </v-icon>
        </v-card>
      </div>
      <v-card class="former-advanced-info">
        <v-tabs color="main-color">
          <v-tab>Domaines de formation</v-tab>
          <v-tab>Agenda</v-tab>

          <v-tab-item key="1">
            <competencieTable :competencies="competencies" readOnly />
          </v-tab-item>
          <v-tab-item key="2">
            <SearchDetailAgenda
              :events="slots"
              :onEventClicked="handleEventClicked"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
    <DetailEventBookModal
      :opened="dialogOpen"
      :event="selectedEvent"
      :onClose="handleModalClosed"
      :onBook="handleEventBooked"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import competencieTable from "../../../components/Competencies/CompetencieTable";
import SearchDetailAgenda from "./SearchDetailAgenda";
import DetailEventBookModal from "./DetailEventBookModal";

export default {
  components: { competencieTable, SearchDetailAgenda, DetailEventBookModal },
  data: () => ({
    loadingData: false,
    competencies: "",
    name: "",
    avatarUrl: "https://picsum.photos/id/11/500/300",
    description: "",
    rating: 4.8,
    slots: [],
    focus: "",
    dialogOpen: false,
    selectedEvent: {},
    weekdays: [1, 2, 3, 4, 5, 6, 0],
  }),
  mounted() {
    this.loadingData = true;

    this.fetchFormerById(this.$route.params.id)
      .then((former) => {
        let data = former.data.data;
        this.name = data.first_name + " " + data.last_name;
        this.competencies = data.domain;
        this.description = data.desc;
        this.slots = data.availablity;
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
      });

    this.$refs.calendar.scrollToTime("08:00");
  },
  methods: {
    ...mapActions("former", ["fetchFormerById"]),
    ...mapActions("event", ["patchEvent"]),
    handleEventClicked(event) {
      this.selectedEvent = event;
      this.dialogOpen = true;
    },
    handleModalClosed() {
      this.selectedEvent = {};
      this.dialogOpen = false;
    },
    handleEventBooked(form_id) {
      this.dialogOpen = false;
      this.patchEvent({
        event_id: form_id,
        formative_id: this.selectedEvent.formative_id,
      });
    },
  },
  computed: {
    ...mapGetters("meta_data", { domains: "getDomains" }),
  },
};
</script>

<style>
.profile-wrapper {
  padding-top: 34px;
  width: 90%;
  margin: auto;
}

.former-core-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.former-core-info-header {
  background: url("../../../assets/Search-SVG/profile-header-background.svg")
    no-repeat;
  background-size: cover;
  height: 280px;
  width: 55%;
  position: relative;
}

.avatar-wrapper {
  position: absolute;
  right: 10%;
  top: 15%;
}

.profile-wrapper .former-core-info .former-profile-img {
  border-radius: 50%;
  object-fit: cover;
  width: 175px;
  height: 175px;
  position: absolute;
  margin: 5px;
}

.avatar-outline {
  height: 185px;
  width: 185px;
  border-radius: 50%;
  border-color: #11514c;
  background-color: white;
  border-style: solid;
  border-width: 10px;
}

.header-info {
  padding: 50px;
}

.header-info .former-name {
  color: white;
  margin: 0;
}

.header-info .rating-container {
  display: flex;
  align-items: center;
}

.former-description {
  display: flex;
  align-items: center;
  height: 280px;
  width: 44%;
}

.former-description > p {
  font-weight: bold;
  padding: 0 16px;
  color: #757575;
}

.former-advanced-info {
  margin-top: 34px;
}
</style>
