<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title class="training-title-container">
        <span class="training-title">{{ event.name || "Disponiblité" }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="training-detail-container">
          <h4>Détails de la disponbilité</h4>
          <v-row class="training-date-wrapper">
            <v-col cols="12" md="1">
              <v-icon>
                mdi-calendar-range
              </v-icon>
            </v-col>
            <v-col cols="12" md="10" class="training-date-content">
              <div>
                <span>Du</span>
                <span> {{ dateFrom }}</span>
              </div>
              <div>
                <span>Au</span>
                <span> {{ dateTo }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider> </v-divider>
          <v-form>
            <h4>Sélectionner une formation</h4>
            <h6>
              Avant de réserver un formateur, vous devez avoir créé une
              formation.Si ce n'est pas fait rendez-vous dans la rubrique mes
              formations/créer une formation.
            </h6>
            <v-autocomplete
              style="padding-top: 4px"
              v-model="selected"
              clearable
              :items="form.events"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <div v-if="selected">
              <h5>Détails de la formation</h5>
              <v-text-field
                :value="form.events[pos].desc"
                label="Description de la formation"
                readonly
              ></v-text-field>
              <v-text-field
                :value="form.events[pos].start"
                label="Début de la formation"
                readonly
              ></v-text-field>
              <v-text-field
                :value="form.events[pos].end"
                label="Fin de la formation"
                readonly
              ></v-text-field>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="onClose">
          Annuler
        </v-btn>
        <v-btn color="#00897f" class="main-color" text @click="onEventBooked">
          Envoyer une demande de réservation
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dayjs from "dayjs";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DetailEventBookModal",
  props: {
    opened: {
      required: false,
      default: false,
    },
    event: {
      required: true,
    },
    onBook: {
      required: true,
    },
    onClose: {
      required: true,
    },
  },
  mounted() {
    this.fetchMyEvent({ limit: 9999, offset: 0 });
    this.selected = null;
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    dateFrom() {
      return dayjs(this.event.start, "YYYY--MM-DD HH:mm").format(
        "DD/MM/YYYY HH:mm"
      );
    },
    dateTo() {
      return dayjs(this.event.end, "YYYY--MM-DD HH:mm").format(
        "DD/MM/YYYY HH:mm"
      );
    },
    pos() {
      return this.form.events.findIndex((x) => x.id === this.selected);
    },
    ...mapGetters("organism", { form: "getMyEvent" }),
  },
  methods: {
    onEventBooked() {
      this.onBook(this.selected);
    },
    ...mapActions("organism", ["fetchMyEvent"]),
  },
};
</script>

<style>
.training-title-container {
  background-color: #00897f;
}

.training-title {
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.training-detail-container {
  padding: 32px 16px 0 16px;
}

.training-date-wrapper {
  align-items: center;
}

.training-date-content {
  display: flex;
  flex-direction: column;
}
</style>
